import React from "react"
import { Helmet } from "react-helmet"

export default props => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{props.data.allContentfulPrivacy.nodes[0].title}</title>
      <link rel="canonical" href="https://startupedia.net/politica-de-privacidad" />
    </Helmet>
      <div className="container mt-5">
        <div className="row mt-5">
          <div
            className="col-md-8 offset-md-2"
            dangerouslySetInnerHTML={{
              __html: props.data.allContentfulPrivacy.nodes[0].body.childMarkdownRemark.html,
            }}
          ></div>
        </div>
      </div>
  </>
)

export const query = graphql`
  query($lang: String!) {
    allContentfulPrivacy(filter: { lang: { eq: $lang } }) {
        nodes {
            lang
            title
            slug
            body {
              childMarkdownRemark {
                html
              }
            }
          }
    }
  }
`
